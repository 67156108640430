import { HandShakeSimpleIcon } from "@mybridge/icons"
import { Box, HStack } from "@mybridge/ui/layout"
import { Link } from "@mybridge/ui/link"
import { Text } from "@mybridge/ui/text"
import { useContext, useMemo } from "react"
import { PostCommentRowContext } from "../context"
import { useRouter } from "next/navigation"
import { InsightPopover } from "../insight-popover"
import { getUserFullName } from "v4/lib/commons"
import { useTranslator } from "@mybridge/ui/custom-hooks/useTranslation"
// import Link from "next/link"

export const PostCommentRowInsightPlate = ({ ...props }) => {

    const { comment, hasInsights, postModalLink } = useContext(PostCommentRowContext)
    const {
        reply_count,
        like_count,
        dislike_count,
        liked_by,
        dislike_by,
        comment_replies
    } = comment ?? {}
    const { t } = useTranslator();
    const likeDislikeCount = useMemo(() => {
        return (like_count ?? 0) + (dislike_count ?? 0)
    }, [like_count, dislike_count])

    const likedBy = liked_by?.map?.(lb => <Box>{getUserFullName(lb)}</Box>)
    const dislikedBy = dislike_by?.map?.(lb => <Box>{getUserFullName(lb)}</Box>)
    const commentBy = comment_replies
        ?.map(v => v?.owner)
        ?.reduceRight?.((arr, cv) => arr?.concat(arr?.find(pv => pv?.id === cv?.id) ? [] : [cv]), [])
        ?.map?.(lb => <Box>{getUserFullName(lb)}</Box>)

    return <>
        {hasInsights ?
            <HStack
                pr={4}
                py={2}
                // borderBottom="1px solid"
                // borderColor="brandGray.500"
                {...props}
            >
                <HStack >
                    <HStack spacing={0}>
                        {like_count > 0 ?
                            <InsightPopover
                                content={likedBy}
                            >
                                <HandShakeSimpleIcon width="14" height="14" color="green" />
                            </InsightPopover>
                            : ""}
                        {dislike_count > 0 ?
                            <InsightPopover
                                content={dislikedBy}
                            >
                                <HandShakeSimpleIcon width="14" height="14" color="red" />
                            </InsightPopover>
                            : ""
                        }
                    </HStack>
                    {likeDislikeCount > 0 ? <Text color="brandGray.700">
                        {likeDislikeCount}
                    </Text> : ""}
                </HStack>
                <HStack
                    flex={1}
             /* eslint-disable @nx/enforce-module-boundaries */
       justifyContent="flex-end"
                >
                    {reply_count > 0 ?
                        <InsightPopover
                            content={commentBy}
                        >
                            <Link
                                shallow={true}
                                color="brandPrimary.500">
                                {reply_count} {t('post.comments')||"Comments"}
                            </Link>
                        </InsightPopover>
                        : ""}
                </HStack>
            </HStack> : ""}
    </>
}