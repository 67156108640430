/* eslint-disable @nx/enforce-module-boundaries */


import { Button } from '@mybridge/ui/button';
import { HStack, Stack } from '@mybridge/ui/layout';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@mybridge/ui/modal';
import { Text } from '@mybridge/ui/text';
import { useContext } from 'react';
import { PostCommentRowContext } from '../../context';
import { useTranslator } from '@mybridge/ui/custom-hooks/useTranslation';

export const PostCommentRowDeleteModal = ({ ...props }) => {
  const { deleteComment, deleteCommentLoading } = useContext(
    PostCommentRowContext
  );
  const { t } = useTranslator();
  return (
    <>
      <Modal size="xl" {...props}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ModalCloseButton />
            {t('otherPage.deletecomment') ||'Delete comment?'}
          </ModalHeader>
          <ModalBody>
            <Stack>
              <Stack>
                <Text>
                {t('otherPage.thiscanbeundone')}
                </Text>
              </Stack>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <HStack justifyContent="flex-end">
              <Button onClick={props?.onClose} variant="secondary">
              {t('buttons.cancel')||"Cancel"}
              </Button>
              <Button
                onClick={deleteComment}
                colorScheme="red"
                isLoading={deleteCommentLoading}
              >
                 {t('remove.profile')||"Remove"}
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
